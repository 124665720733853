// const httpUrl = 'http://all.biubug.cn/api'
// const httpUrl = 'http://flysky.net/api'
const httpUrl = '/api'

export const getIndexApi = async () => {
  const { data } = await useServerRequest(`${httpUrl}/index/index`)
  return data
}
export const getStaffTeam = async () => {
  const { data } = await useServerRequest(`${httpUrl}/index/staffTeam`)
  return data
}

/* 导航 */
export const getCourseNav = async () => {
  const { data } = await useServerRequest(`${httpUrl}/Course/getCourseNav`)
  return data
}

/* 搜索 */
export const getSearchNews = async (params) => {
  const data: any = await useClientRequest(`${httpUrl}/news/search`, { method: 'POST', body: params })
  return data.data
}

/* 课程服务 */
export const getCourseByType = async (type) => {
  const { data } = await useServerRequest(`${httpUrl}/Course/getCourseByType`, { method: 'POST', params: { type } })
  return data
}

/* 渠道合作 */
export const getChannelCoopApi = async () => {
  const { data } = await useServerRequest(`${httpUrl}/winwin/channelCoop`)
  return data
}

/* 生态合作 */
export const getEcologyCoopApi = async () => {
  const { data } = await useServerRequest(`${httpUrl}/winwin/ecologyCoop`)
  return data
}
/* 合资企业 */
export const getJointVenture = async () => {
  const { data } = await useServerRequest(`${httpUrl}/about/jointVenture`)
  return data
}
/* 团队师资 */
export const getTeamList = async () => {
  const { data } = await useServerRequest(`${httpUrl}/about/getTeamList`)
  return data
}
/* 团队个人详情资料 */
export const getTeamById = async (id) => {
  const data: any = await useClientRequest(`${httpUrl}/about/getTeamById`, { params: { id } })
  return data?.data
}
export const getIndexTeamById = async (id) => {
  const data: any = await useClientRequest(`${httpUrl}/index/getTeamById`, { params: { id } })
  return data?.data
}

/* 公司介绍 */
export const getAboutUs = async () => {
  const { data }: any = await useServerRequest(`${httpUrl}/about/getAboutUs`)
  return data
}

/* 网站基本信息 */
export const getSiteInfo = async () => {
  const { data } = await useServerRequest(`${httpUrl}/info/siteInfo`)
  return data.value
}
/* 招聘需求页面 */
export const getJobIndex = async () => {
  const { data } = await useServerRequest(`${httpUrl}/job/index`)
  return data.value
}

/* 招聘信息详情 */
export const getJobDetailById = async (params) => {
  const data: any = await useClientRequest(`${httpUrl}/job/getJobDetailById`, { params })
  return data.data
}

/* 招聘需求列表 */
export const getSearchJobList = async (params) => {
  const data: any = await useClientRequest(`${httpUrl}/job/searchJobList`, { params })
  return data.data
}

/* 认证信息分类 */
export const getAuthClass = async () => {
  const { data } = await useServerRequest(`${httpUrl}/news/getAuthClass`)
  return data
}

/* 认证信息列表 */
export const getAuthList = async (params) => {
  const data: any = await useClientRequest(`${httpUrl}/news/getAuthList`, { params })
  return data.data
}

/* 文章详情（共用） */
export const getNewsDetail = async (id) => {
  const { data }: any = await useServerRequest(`${httpUrl}/news/getNewsDetail`, { params: { id } })
  return data
}

export const getStrategyDetail = async (id) => {
  const { data }: any = await useServerRequest(`${httpUrl}/course/getStrategyDetail`, { params: { id } })
  return data
}
/* 行业资讯 */
export const getIndustryNews = async (params) => {
  const { data }: any = await useServerRequest(`${httpUrl}/news/getIndustryNews`, { params })
  return data
}
export const getSoftwareAndHardware = async () => {
  const { data }: any = await useServerRequest(`${httpUrl}/index/softwareAndHardware`)
  return data
}
export const getCompanyTrainDetail = async () => {
  const { data }: any = await useServerRequest(`${httpUrl}/index/companyTrainDetail`)
  return data
}

export const getIndustryNewsLazy = async (params) => {
  const { data }: any = await useClientRequest(`${httpUrl}/news/getIndustryNews`, { params })
  return data
}
