type FetchType = typeof $fetch
export type FetchOptions = Parameters<FetchType>[1]

export const useClientRequest = <T = unknown>(url, data: { opts?: FetchOptions; params?: any; method?: any; body?: any }) => {
  const defaultOptions: FetchOptions = {
    onRequest({ options }) {
      options.headers = {
        ...options.headers,
        'Accept-Language': localStorage.getItem('locale') === 'en' ? 'en-us' : 'zh-cn'
      }
    },
    onResponse({ request, response, options }) {},
    onResponseError({ request, response, options }) {
      handleError(response)
    }
  }

  return $fetch<T>(url, { ...defaultOptions, ...data.opts, params: data.params, method: data.method, body: data.body })
}
